<template>
   <a class="dropdown-item" href="javascript:;" @click="irParaTela">
      <div class="row align-items-center">
         <div class="col-2">
            <div class="notify bg-light-secondary color-theme"><i :class="route.meta.icon"></i></div>
         </div>
         <div class="col-10">
            <h6 class="msg-name limitador-1">{{ notificacao.titulo }}</h6>
            <p class="msg-time limitador-1 mb-1">{{ notificacao.descricao }}</p>
            <p class="msg-time limitador-1 text-end font-10">
               <span>{{ notificacao.data.dayOfMonth.toString().padStart(2, '0') +'/'+ notificacao.data.monthValue.toString().padStart(2, '0') +'/'+ notificacao.data.year }} </span>
               <span>{{ notificacao.data.hour.toString().padStart(2, '0') +':'+ notificacao.data.minute.toString().padStart(2, '0') }}</span>   
            </p>
         </div>
      </div>
   </a>
</template>

<script>

import router from '@/router'
import { mapState } from 'vuex'

export default {
   name: 'Notificacao',
   props: ["notificacao", "index"],
   data: function () {
		return {
         route: router.options.routes.find(tela => tela.name == this.notificacao.tela)
      }
   },
   computed: {
      ... mapState({
         tabs: state => state.tabs
      })
   },
   methods: {
      irParaTela : function () {
         router.push(this.route.path.replace(':idTab', this.tabs.length))
      }
   }
}

</script>