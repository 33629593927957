import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import jQuery from 'jquery'
import vSelect from "vue-select"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
import Axios from 'axios'
import Swal from 'sweetalert2'
import VueHtmlToPaper from 'vue-html-to-paper'
import QrcodeVue from 'qrcode.vue'
import VueTheMask from 'vue-the-mask'
import "vue-select/dist/vue-select.css"
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import highcharts from 'highcharts-vue'
import JsonExcel from "vue-json-excel"
import autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
global.jQuery = jQuery
global.$ = jQuery

Vue.prototype.$axios = Axios
Vue.prototype.$toast = Swal.mixin({
   toast: true,
   position: 'top-end',
   showConfirmButton: false,
   timer: 3500,
   timerProgressBar: true,
   didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
   }
})

Vue.directive('focus', {
   inserted: function (el) {
      try {
         if (!window.matchMedia("(pointer: coarse)").matches) {
            el.focus()
         }
      } catch (error) {
         console.log(error)
      }
   }
})

require('@/assets/plugins/simplebar/simplebar.css')
require('@/assets/plugins/perfect-scrollbar/perfect-scrollbar.css')
require('@/assets/plugins/metisMenu/metisMenu.min.css')
require('@/assets/css/fontAwesome5Pro.css')
require('@/assets/css/bootstrap.min.css')
require('@/assets/css/app.css')
require('@/assets/css/icons.css')
require('@/assets/css/dark-theme.css')
require('@/assets/css/semi-dark.css')
require('@/assets/css/header-colors.css')

require('@/assets/plugins/metisMenu/metisMenu.min.js')
require('@/assets/js/bootstrap.bundle.min.js')

Vue.use(highcharts)
Vue.use(VueTheMask)
Vue.use(autocomplete)
Vue.component(Cropper)
Vue.use(VueHtmlToPaper)
Vue.component("v-select", vSelect)
Vue.component('qrcode-vue', QrcodeVue)
Vue.component('datePicker', DatePicker)
Vue.component("downloadExcel", JsonExcel)

Vue.config.productionTip = false

new Vue({
   router,
   store,
   render: h => h(App)
}).$mount('#app')
