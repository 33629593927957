<template>
   <header>
      <div class="topbar d-flex align-items-center">
         <nav class="navbar navbar-expand">
            <div class="topbar-logo-header">
               <div class="">
                  <img src="@/assets/images/logo-icon.png" class="logo-icon" alt="logo icon">
               </div>
               <div class="">
                  <h4 class="logo-text">Carolina</h4>
               </div>
            </div>
            <div class="mobile-toggle-menu"><i class='bx bx-menu'></i></div>
            <div class="search-bar flex-grow-1"></div>
				<div class="top-menu ms-auto">
					<ul class="navbar-nav align-items-center">
						<li class="nav-item" v-if="urlAjuda">
							<a class="nav-link" :href="urlAjuda" target="_blank" role="button"><i class='bx bx-help-circle'></i></a>
						</li>
						<li class="nav-item dropdown dropdown-large">
							<a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
								<span class="alert-count" v-if="notificacoes.length > 0">{{ notificacoes.length }}</span>
								<i class='bx bx-bell'></i>
							</a>
							<div class="dropdown-menu dropdown-menu-end">
								<a href="javascript:;">
									<div class="msg-header"><p class="msg-header-title">Notificações</p></div>
								</a>
								<div class="header-notifications-list">
									<notificacao v-for="(notificacao, index) in notificacoes" :key="index" :notificacao="notificacao" :index="index" />

									<a class="dropdown-item" href="javascript:;" v-if="notificacoes.length == 0">
										<div class="d-flex align-items-center">
											<div class="flex-grow-1 msg-info text-center my-5">
												<i class="fal fa-bell-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
												<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhuma notificação encontrada</h4>
											</div>
										</div>
									</a>
								</div>
							</div>
						</li>
					</ul>
				</div>
            <div class="user-box dropdown">
               <a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img :src="dadosUsuario.usuarioFoto == null ? '' : dadosUsuario.usuarioFoto" @error="imageError" class="user-img rounded-1 bg-white" ref="avatar" alt="Avatar">
                  <div class="user-info ps-3">
                     <p class="user-name mb-0">{{ String(dadosUsuario.usuarioNome).length >= 15 ? dadosUsuario.usuarioNome.substring(0, 15) + '...' : dadosUsuario.usuarioNome }}</p>
                     <p class="designattion mb-0">{{ String(dadosUsuario.usuarioEmail).length >= 15 ? dadosUsuario.usuarioEmail.substring(0, 15) + '...' : dadosUsuario.usuarioEmail }}</p>
                  </div>
               </a>
               <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                     <a class="dropdown-item" href="javascript:;" data-bs-toggle="modal" data-bs-target="#modalAlterarFoto" @click="modal.banner = dadosUsuario.usuarioFoto;">
								<i class="far fa-camera font-12 me-2"></i><span> Alterar foto</span>
							</a>
                  </li>
                  <li>
                     <a class="dropdown-item" href="javascript:;" data-bs-toggle="modal" data-bs-target="#modalAlterarSenha" @click="modal.atual = null; modal.novo = null;">
								<i class="far fa-key font-12 me-2"></i><span> Alterar senha</span>
							</a>
                  </li>
						<!-- <li> -->
						<li v-if="dadosUsuario.usuarioId == dadosUsuario.idUsuarioAdm">
							<router-link class="dropdown-item" :to="'/pacotes/'+ tabs.length"><i class="far fa-cog"></i>Pacotes</router-link>
						</li>
                  <li>
                     <a class="dropdown-item" href="javascript:;" @click="deslogar"><i class="far fa-sign-out-alt font-12 me-2"></i><span> Sair</span></a>
                  </li>
               </ul>
            </div>
         </nav>
      </div>

      <div class="nav-container">
			<div class="mobile-topbar-header">
				<div>
					<img src="@/assets/images/logo-icon.png" class="logo-icon" alt="logo icon">
				</div>
				<div>
					<h4 class="logo-text">Carolina</h4>
				</div>
				<div class="toggle-icon ms-auto"><i class='bx bx-arrow-to-left'></i></div>
			</div>
			<nav class="topbar-nav">
				<ul class="metismenu" id="menu">
					<li>
						<router-link to="/paginaInicial/0"><i class='bx bx-home-circle color-theme'></i><span>Página inicial</span></router-link>
					</li>
					<li>
						<a href="javascript:;" class="has-arrow" v-if="permissoesCaixa.some(p => dadosUsuario.usuarioPermissoes.includes(p))">
							<i class="bx bx-desktop color-theme"></i><span>Caixa</span>
						</a>
						<a href="javascript:;" class="disabled" v-else><i class="bx bx-lock-alt color-theme"></i><span>Caixa</span></a>
						<ul>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Caixa | Visualizar caixas') && dadosUsuario.usuarioPdv != null">
								<router-link :to="'/caixa/'+ tabs.length +'?idPdv='+ dadosUsuario.usuarioPdv"><i class="bx bx-right-arrow-alt"></i><span>Caixa atual</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Caixa | Visualizar caixas')">
								<router-link :to="'/caixas/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Caixas</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Caixa | Visualizar fechamentos')">
								<router-link :to="'/fechamentos/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Fechamentos</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Caixa | Visualizar cupons gerados')">
								<router-link :to="'/cuponsGerados/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Cupons gerados</span></router-link>
							</li>
						</ul>
					</li>
					<li>
						<a href="javascript:;" class="has-arrow" v-if="permissoesResultados.some(p => dadosUsuario.usuarioPermissoes.includes(p))">
							<i class="bx bx-line-chart color-theme"></i><span>Resultados</span>
						</a>
						<a href="javascript:;" class="disabled" v-else><i class="bx bx-lock-alt color-theme"></i><span>Resultados</span></a>
						<ul>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Resultados | Visualizar vendas')">
								<router-link :to="'/vendas/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Vendas/Sangrias</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Resultados | Visualizar saídas de produtos')">
								<router-link :to="'/saidasProdutos/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Saídas de produtos (ABC)</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Resultados | Visualizar entradas de produtos')">
								<router-link :to="'/entradasProdutos/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Entradas de produtos (ABC)</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Resultados | Visualizar ticket médio')">
								<router-link :to="'/ticketMedio/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Ticket médio</span></router-link>
							</li>
							<li>
								<a href="javascript:;" class="has-arrow" v-if="dadosUsuario.usuarioPermissoes.includes('Resultados | Visualizar entradas e saídas')">
									<i class="far fa-exchange font-11"></i><span>Entradas e saídas</span>
								</a>
								<a href="javascript:;" class="disabled" v-else><i class="bx bx-lock-alt color-theme"></i><span>Entradas e saídas</span></a>
								<ul>
									<li>
										<router-link :to="'/notasEntradasSaidas/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Notas</span></router-link>
									</li>
									<li>
										<router-link :to="'/entradasSaidas/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Relatórios</span></router-link>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>
						<a href="javascript:;" class="has-arrow" v-if="permissoesFiscal.some(p => dadosUsuario.usuarioPermissoes.includes(p))">
							<i class="bx bx-file-blank color-theme"></i><span>Fiscal</span>
						</a>
						<a href="javascript:;" class="disabled" v-else><i class="bx bx-lock-alt color-theme"></i><span>Fiscal</span></a>
						<ul>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Fiscal | Visualizar notas')">
								<router-link :to="'/notas/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Notas</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Fiscal | Emitir notas')">
								<router-link :to="'/emitirNota/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Emitir notas</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Fiscal | Visualizar fornecedores/beneficiários')">
								<router-link :to="'/fornecedores/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Fornecedores</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Fiscal | Visualizar tributações')">
								<router-link :to="'/tributacoes/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Tributações</span></router-link>
							</li>
						</ul>
					</li>
					<li>
						<a href="javascript:;" class="has-arrow" v-if="permissoesProdutos.some(p => dadosUsuario.usuarioPermissoes.includes(p))">
							<i class="bx bx-coffee-togo color-theme"></i><span>Produtos</span>
						</a>
						<a href="javascript:;" class="disabled" v-else><i class="bx bx-lock-alt color-theme"></i><span>Produtos</span></a>
						<ul>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Produtos | Visualizar produtos')">
								<router-link :to="'/meusProdutos/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Meus produtos</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Produtos | Visualizar preços')">
								<router-link :to="'/precos/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Preços</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Produtos | Visualizar categorias')">
								<router-link :to="'/categorias/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Categorias</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Produtos | Visualizar marcas')">
								<router-link :to="'/marcas/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Marcas</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Produtos | Visualizar estoque por produto')">
								<router-link :to="'/estoquesProduto/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Estoque por produto</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Produtos | Emitir ajuste de estoque')">
								<router-link :to="'/ajusteEstoque/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Ajuste de estoque</span></router-link>
							</li>
						</ul>
					</li>
					<li>
						<a href="javascript:;" class="has-arrow" v-if="permissoesAutomacao.some(p => dadosUsuario.usuarioPermissoes.includes(p))">
							<i class="bx bx-bot color-theme"></i><span>Automação</span>
						</a>
						<a href="javascript:;" class="disabled" v-else><i class="bx bx-lock-alt color-theme"></i><span>Automação</span></a>
						<ul>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Automação | Visualizar cargas')">
								<router-link :to="'/cargas/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Cargas</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Automação | Visualizar ofertas')">
								<router-link :to="'/ofertas/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Ofertas</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Automação | Visualizar combos (packs)')">
								<router-link :to="'/combosPacks/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Combos (Packs)</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Automação | Visualizar vasilhames')">
								<router-link :to="'/vasilhames/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Vasilhames</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Automação | Visualizar clube de desconto')">
								<router-link :to="'/clubeDesconto/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Clubes de desconto</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Automação | Visualizar clientes')">
								<router-link :to="'/clientes/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Clientes</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Automação | Visualizar receitas')">
								<router-link :to="'/receitas/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Receitas</span></router-link>
							</li>
							<li>
								<a href="javascript:;" class="has-arrow" v-if="permissoesConvenios.some(p => dadosUsuario.usuarioPermissoes.includes(p))">
									<i class="bx bx-wallet"></i><span>Convênios</span>
								</a>
								<a href="javascript:;" class="disabled" v-else><i class="bx bx-lock-alt color-theme"></i><span>Convênios</span></a>
								<ul>
									<li v-if="dadosUsuario.usuarioPermissoes.includes('Convênios | Visualizar afiliados')">
										<router-link :to="'/afiliados/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Afiliados</span></router-link>
									</li>
									<li v-if="dadosUsuario.usuarioPermissoes.includes('Convênios | Visualizar relatórios')">
										<router-link :to="'/relatorios/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Relatórios</span></router-link>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>
						<a href="javascript:;" class="has-arrow" v-if="permissoesRecebimento.some(p => dadosUsuario.usuarioPermissoes.includes(p))">
							<i class="bx bx-package color-theme"></i><span>Recebimento</span>
						</a>
						<a href="javascript:;" class="disabled" v-else><i class="bx bx-lock-alt color-theme"></i><span>Recebimento</span></a>
						<ul>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Recebimento | Entrada de notas')">
								<router-link :to="'/entradaNota/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Entrada de notas</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Recebimento | Visualizar notas entregues')">
								<router-link :to="'/notasEntregues/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Nota entregues</span></router-link>
							</li>
						</ul>
					</li>
					<li>
						<a href="javascript:;" class="has-arrow" v-if="permissoesCompras.some(p => dadosUsuario.usuarioPermissoes.includes(p))">
							<i class="bx bx-cart color-theme"></i><span>Compras</span>
						</a>
						<a href="javascript:;" class="disabled" v-else><i class="bx bx-lock-alt color-theme"></i><span>Compras</span></a>
						<ul>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Compras | Visualizar pedidos')">
								<router-link :to="'/pedidos/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Pedidos</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Compras | Visualizar cotações')">
								<router-link :to="'/cotacoes/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Cotações</span></router-link>
							</li>
						</ul>
					</li>
					<li>
						<a href="javascript:;" class="has-arrow" v-if="permissoesEcommerce.some(p => dadosUsuario.usuarioPermissoes.includes(p))">
							<i class="bx bx-at color-theme"></i><span>Ecommerce</span>
						</a>
						<a href="javascript:;" class="disabled" v-else><i class="bx bx-lock-alt color-theme"></i><span>Ecommerce</span></a>
						<ul>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Ecommerce | Visualizar produtos')">
								<router-link :to="'/produtos/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Produtos</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Ecommerce | Visualizar pedidos')">
								<router-link :to="'/pedidosEcommerce/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Pedidos</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Ecommerce | Visualizar vendas')">
								<router-link :to="'/vendasEcommerce/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Vendas</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Ecommerce | Editar configurações')">
								<router-link :to="'/configuracoes/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Configurações</span></router-link>
							</li>
						</ul>
					</li>
					<li>
						<a href="javascript:;" class="has-arrow" v-if="permissoesFinanceiro.some(p => dadosUsuario.usuarioPermissoes.includes(p))">
							<i class="bx bx-dollar color-theme"></i><span>Financeiro</span>
						</a>
						<a href="javascript:;" class="disabled" v-else><i class="bx bx-lock-alt color-theme"></i><span>Financeiro</span></a>
						<ul>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Financeiro | Visualizar plano de contas')">
								<router-link :to="'/planoContas/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Plano de contas</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Financeiro | Visualizar gastos')">
								<router-link :to="'/lancamentoGastos/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Lançamento de gastos</span></router-link>
							</li>
						</ul>
					</li>
					<li>
						<a href="javascript:;" class="has-arrow" v-if="permissoesConfiguracoes.some(p => dadosUsuario.usuarioPermissoes.includes(p))">
							<i class="bx bx-cog color-theme"></i><span>Configurações</span>
						</a>
						<a href="javascript:;" class="disabled" v-else><i class="bx bx-lock-alt color-theme"></i><span>Configurações</span></a>
						<ul>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Configuração | Visualizar usuários')">
								<router-link :to="'/usuarios/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Usuários</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Configuração | Visualizar lojas')">
								<router-link :to="'/lojas/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Lojas</span></router-link>
							</li>
							<li v-if="dadosUsuario.usuarioPermissoes.includes('Configuração | Visualizar unidades de medida')">
								<router-link :to="'/unidadesMedida/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Unidades de medida</span></router-link>
							</li>
							<li>
								<a href="javascript:;" class="has-arrow" v-if="permissoesEstoque.some(p => dadosUsuario.usuarioPermissoes.includes(p))">
									<i class="bx bx-box"></i><span>Estoque</span>
								</a>
								<a href="javascript:;" class="disabled" v-else><i class="bx bx-lock-alt color-theme"></i><span>Estoque</span></a>
								<ul>
									<li v-if="dadosUsuario.usuarioPermissoes.includes('Configuração | Visualizar gerenciamento de estoques')">
										<router-link :to="'/gerenciamentoEstoque/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Gerenciamento</span></router-link>
									</li>
									<li v-if="dadosUsuario.usuarioPermissoes.includes('Produtos | Visualizar operações estoque')">
										<router-link :to="'/operacoesEstoque/'+ tabs.length"><i class="bx bx-right-arrow-alt"></i><span>Operações</span></router-link>
									</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>
			</nav>
		</div>

		<!-- modalAlterarSenha -->
      <div class="modal fade" id="modalAlterarSenha" tabindex="-1" aria-labelledby="modalAlterarSenhaLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalAlterarSenhaLabel">Alterar senha</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card">
							<div class="card-body p-12">
								<div class="col-12 mb-3">
									<label><i class="far fa-barcode color-theme font-12 me-1"></i> Senha atual *</label>
									<input type="text" class="form-control key" v-model="modal.atual" maxlength="200" />
								</div>
								<div class="col-12">
									<label><i class="far fa-barcode color-theme font-12 me-1"></i> Senha nova *</label>
									<input type="text" class="form-control key" v-model="modal.novo" maxlength="200" />
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarSenha">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
				</div>
			</div>
		</div>

		<!-- modalAlterarFoto -->
      <div class="modal fade" id="modalAlterarFoto" tabindex="-1" aria-labelledby="modalAlterarFotoLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalAlterarFotoLabel">Alterar foto</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card">
							<div class="card-body p-12">
								<div class="row m-0">
									<uploadImagem :resultado="modal.banner" label="Foto de perfil" widthCropper="col-md-6" @set="modal.banner = $event;" />
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarFoto">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
				</div>
			</div>
		</div>
   </header>
</template>

<script>

import Vue from 'vue'
import $ from 'jquery'
import { mapState } from 'vuex'
import notificacao from '@/components/Notificacao.vue'
import uploadImagem from '@/components/UploadImagem.vue'

export default {
	name: 'MenuNavBar',
	data: function () {
		return {
			permissoesCaixa: ['Caixa | Visualizar cupons gerados', 'Caixa | Visualizar caixas', 'Caixa | Visualizar fechamentos'],
			permissoesResultados: ['Resultados | Visualizar vendas', 'Resultados | Visualizar saídas de produtos', 'Resultados | Visualizar entradas de produtos', 'Resultados | Visualizar ticket médio', 'Resultados | Visualizar entradas e saídas'],
			permissoesFiscal: ['Fiscal | Emitir notas', 'Fiscal | Visualizar notas', 'Fiscal | Visualizar fornecedores/beneficiários', 'Fiscal | Visualizar tributações'],
			permissoesProdutos: ['Produtos | Visualizar produtos', 'Produtos | Visualizar estoque por produto', 'Produtos | Emitir ajuste de estoque', 'Produtos | Visualizar preços', 'Produtos | Visualizar categorias', 'Produtos | Visualizar subcategorias', 'Produtos | Visualizar marcas'],
			permissoesAutomacao: ['Automação | Visualizar vasilhames', 'Automação | Visualizar cargas', 'Automação | Visualizar ofertas', 'Automação | Visualizar receitas', 'Automação | Visualizar clube de desconto', 'Automação | Visualizar clientes', 'Automação | Visualizar combos (packs)', 'Convênios | Visualizar afiliados', 'Convênios | Visualizar relatórios'],
			permissoesRecebimento: ['Recebimento | Entrada de notas', 'Recebimento | Visualizar notas liberadas'],
			permissoesCompras: ['Compras | Visualizar pedidos', 'Compras | Visualizar cotações'],
			permissoesEcommerce: ['Ecommerce | Visualizar produtos', 'Ecommerce | Visualizar pedidos', 'Ecommerce | Visualizar vendas', 'Ecommerce | Editar configurações'],
			permissoesConvenios: ['Convênios | Visualizar afiliados', 'Convênios | Visualizar relatórios'],
			permissoesEstoque: ['Produtos | Visualizar operações estoque', 'Produtos | Visualizar gerenciamento de estoques'],
			permissoesFinanceiro: ['Financeiro | Visualizar plano de contas', 'Financeiro | Visualizar gastos'],
			permissoesConfiguracoes: ['Configuração | Visualizar usuários', 'Configuração | Visualizar lojas', 'Configuração | Visualizar unidades de medida', 'Configuração | Visualizar gerenciamento de estoques', 'Produtos | Visualizar operações estoque'],
			notificacoes: [],
			modal: {'banner': null, 'atual': null, 'novo': null, 'tipo': 'Senha', 'id': null}
		}
	},
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         isLogado: state => state.isLogado,
         urlAjuda: state => state.urlAjuda,
         urlRest: state => state.urlRest,
         tabs: state => state.tabs
      })
   },
	watch: {
		isLogado : function (value) {
			if (value) {
				this.getNotificacoes()
			}
		}
	},
	components: {
		notificacao, uploadImagem
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      deslogar : function () {
			this.$store.dispatch('deslogar')
      },
		getNotificacoes : function () {
			this.notificacoes = []

			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoes/getNotificacoes'

			}).then(response => this.notificacoes = response.data);
		},
		salvarSenha : function () {
			if ((this.modal.atual == null || String(this.modal.atual).trim().length == 0) || this.modal.novo == null || String(this.modal.novo).trim().length == 0) {
				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			this.modal.id = this.dadosUsuario.usuarioId;
			this.$store.dispatch('salvarSenhaUsuario', this.modal).then(() => $('#modalAlterarSenha').modal('hide'))
		},
		salvarFoto : function () {
			this.$store.dispatch('base64toFile', Array.of(this.modal.banner)).then((banner) => {
				if (banner.length == 0) {
					return
				}

				this.$store.dispatch('alternarTelaCarregamento', true)

				let dataForm = new FormData();
				dataForm.append('id', this.dadosUsuario.usuarioId);

				if (banner[0] instanceof File) {
					dataForm.append('banner', banner[0], String(banner[0].name).replace(/[^\w\s.]/gi, ''));
				}
	
				this.$axios({
					method: 'post',
					url: this.urlRest +'configuracoes/saveFotoUsuario',
					headers: {'Content-Type': 'multipart/form-data'},
					data: dataForm
	
				}).then(() => {
					this.$refs.avatar.src = this.modal.banner
					$('#modalAlterarFoto').modal('hide')
					
					this.$toast.fire({
						icon: 'success',
						title: 'Foto alterada!'
					});
				}).catch((error) => {
					if (error.response != undefined) {
						if (error.response.status == 408) {
							this.$store.dispatch('deslogar')
	
							this.$toast.fire({
								icon: 'warning',
								title: 'Sessão expirada!'
							});
						} else {
							this.$toast.fire({
								icon: 'error',
								title: 'Erro: ' + error.response.status
							});
						}
					}
				}).finally(() => {
					this.$store.dispatch('alternarTelaCarregamento', false)
				});
			})
		}
	},
   mounted() {
      Vue.nextTick(function () {
			$(function () {
				for (var i = window.location, o = $(".metismenu li a").filter(function () {
					return this.href == i;
				}).addClass("").parent().addClass("");;) {
					if (!o.is("li")) break;
					o = o.parent("").addClass("").parent("").addClass("");
				}
				
				$('#menu').metisMenu();
			});

         $(".mobile-toggle-menu").on("click", function () {
            $(".wrapper").addClass("toggled");
         });

         $(".toggle-icon").click(function () {
            if ($(".wrapper").hasClass("toggled")) {
               // unpin sidebar when hovered
               $(".wrapper").removeClass("toggled");
               $(".sidebar-wrapper").unbind("hover");
            } else {
               $(".wrapper").addClass("toggled");
               $(".sidebar-wrapper").hover(function () {
                  $(".wrapper").addClass("sidebar-hovered");
               }, function () {
                  $(".wrapper").removeClass("sidebar-hovered");
               })
            }
         });
		}.bind(this));
   }
}

</script>